import { gql } from '@apollo/client';
import { ObjectID, String } from '../queries';
import { GuestReportOutputMutation } from './create-guest-report-atmnha';
import { Date } from './update-user-v2';

export interface UpdateGuestReportInputType {
  realEstateId?: String;
  fullName?: String;
  phone?: String;
  idNumber?: String;
  yearOfBirth?: String;
  address?: String;
  email?: String;
  time?: Date;
  description?: String;
  attachments?: String[];
}
export interface UpdateGuestReportATMNhaData {
  updateGuestReportATMNha?: GuestReportOutputMutation;
}
export interface UpdateGuestReportATMNhaVariables {
  id: ObjectID;
  input?: UpdateGuestReportInputType;
}

export const UPDATE_GUEST_REPORT_ATMNHA = gql`
  mutation updateGuestReportATMNha($id: ObjectID!, $input: UpdateGuestReportInputType)
  @api(name: realEstate) {
    updateGuestReportATMNha(id: $id, input: $input) {
      success
      payload {
        id
      }
    }
  }
`;
