import { gql } from '@apollo/client';
import { FromToDate } from './get-b2c-users';
import { ContractOutputType } from './get-list-contract-real-estate-user';
import { Int, String } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export interface ListContract extends PaginationOf {
  items?: ContractOutputType[];
}

export interface GetListContractTypeData {
  getListContractType?: ListContract;
}
export interface GetListContractTypeVariables {
  name?: String;
  code?: String;
  search?: String;
  createdBy?: String;
  updatedBy?: String;
  updatedAt?: FromToDate;
  createdAt?: FromToDate;
  page?: Int;
  limit?: Int;
}

export const GET_LIST_CONTRACT_TYPE = gql`
  query getListContractType(
    $name: String
    $code: String
    $search: String
    $createdBy: String
    $updatedBy: String
    $updatedAt: FromToDate
    $createdAt: FromToDate
    $page: Int
    $limit: Int
  ) @api(name: realEstate) {
    getListContractType(
      name: $name
      code: $code
      search: $search
      createdBy: $createdBy
      updatedBy: $updatedBy
      updatedAt: $updatedAt
      createdAt: $createdAt
      page: $page
      limit: $limit
    ) {
      hasNextPage
      limit
      page
      prevPage
      total
      hasPrevPage
      nextPage
      pagingCounter
      totalPages
      items {
        id
        code
        name
      }
    }
  }
`;
