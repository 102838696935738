import { SVGProps } from 'react';

const IconEdit2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.4999 18H10.8333M2.08325 18.4167L6.70764 16.638C7.00343 16.5243 7.15132 16.4674 7.28968 16.3931C7.41259 16.3272 7.52975 16.251 7.63995 16.1655C7.76402 16.0692 7.87606 15.9572 8.10015 15.7331L17.4999 6.33332C18.4204 5.41285 18.4204 3.92046 17.4999 2.99999C16.5795 2.07951 15.0871 2.07951 14.1666 2.99999L4.76682 12.3998C4.54273 12.6238 4.43069 12.7359 4.33441 12.86C4.2489 12.9702 4.17275 13.0873 4.10678 13.2102C4.03251 13.3486 3.97563 13.4965 3.86186 13.7923L2.08325 18.4167ZM2.08325 18.4167L3.79835 13.9575C3.92108 13.6384 3.98245 13.4788 4.0877 13.4057C4.17969 13.3419 4.2935 13.3177 4.4035 13.3387C4.52936 13.3627 4.65024 13.4836 4.89199 13.7254L6.77456 15.6079C7.01631 15.8497 7.13719 15.9706 7.16122 16.0964C7.18223 16.2064 7.15807 16.3202 7.09421 16.4122C7.02112 16.5175 6.86158 16.5788 6.54248 16.7016L2.08325 18.4167Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconEdit2 };
