import { gql } from '@apollo/client';
import { DateTime, Float, ObjectID, String, TypeCustomerType } from '../queries';
import { ContractRealEstateOutputMutation } from './create-contract-real-estate';

export interface UpdateContractRealEstateInputType {
  fullName?: String;
  typeCustomer?: TypeCustomerType;
  phone?: String;
  contractId?: ObjectID;
  nameContract?: String;
  content?: String;
  contractDate?: DateTime;
  contractValue?: Float;
  startDate?: DateTime;
  endDate?: DateTime;
  attachments?: String[];
  productId?: ObjectID;
}

export interface UpdateContractRealEstateUserData {
  updateContractRealEstateUser?: ContractRealEstateOutputMutation;
}
export interface UpdateContractRealEstateUserVariables {
  id: ObjectID;
  input?: UpdateContractRealEstateInputType;
}

export const UPDATE_CONTRACT_REAL_ESTATE_USER = gql`
  mutation updateContractRealEstateUser($id: ObjectID!, $input: UpdateContractRealEstateInputType)
  @api(name: realEstate) {
    updateContractRealEstateUser(input: $input, id: $id) {
      success
      msg
      payload {
        id
      }
    }
  }
`;
