import ChatProvider from 'components/providers/chat-provider';
import { CHAT_ENABLE } from 'globalConstants';
import { isEqual } from 'lodash';
import { detectDeviceByUserAgent } from 'utils';
import Chat from './chat';

const Modules = () => {
  if (isEqual(typeof window, 'undefined')) {
    return null;
  } else {
    const userAgent = window.navigator.userAgent;
    const device = detectDeviceByUserAgent(userAgent);

    return isEqual(device, 'mobile') ? null : isEqual(device, 'desktop') ? (
      isEqual(CHAT_ENABLE, 'true') ? (
        <ChatProvider>
          <Chat />
        </ChatProvider>
      ) : null
    ) : null;
  }
};

export default Modules;
