import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { DateTime, ID, Int, ObjectID, String, UserType } from '../queries';

export enum CommentAbleType {
  realEstate = 'realEstate',
  guestReport = 'guestReport',
  contractRealEstate = 'contractRealEstate',
  leadRealEstate = 'leadRealEstate',
}

export interface CommentOutputType {
  id?: ID;
  commentAbleId?: String;
  user?: UserType;
  parentComment?: String;
  commentAbleType?: String;
  content?: String;
  companyId?: String;
  createdAt?: DateTime;
  updatedAt?: DateTime;
}
export interface CommentListOutputType {
  id?: ID;
  commentAbleId?: String;
  user?: UserType;
  parentComment?: String;
  commentAbleType?: String;
  content?: String;
  commentChildren?: CommentOutputType[];
  totalChildren?: Int;
  companyId?: String;
  createdAt?: DateTime;
  updatedAt?: DateTime;
}
export interface CommentOutputMutation extends MutationOf {
  payload?: CommentListOutputType;
}
export interface CommentInputType {
  commentAbleId: ObjectID;
  parentComment?: String;
  commentAbleType: CommentAbleType;
  content: String;
}

export interface CreatedCommentData {
  createdComment?: CommentOutputMutation;
}
export interface CreatedCommentVariables {
  input?: CommentInputType;
}

export const CREATED_COMMENT = gql`
  mutation createdComment($input: CommentInputType) @api(name: realEstate) {
    createdComment(input: $input) {
      msg
      success
      payload {
        id
        commentAbleId
        user {
          id
          fullName
          phones
          avatar {
            ... on FileWithFullUrls {
              fileId
              originalUrl
            }
          }
        }
        parentComment
        commentAbleType
        content
        commentChildren {
          id
          commentAbleId
          user {
            id
            fullName
            phones
            avatar {
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
          }
          parentComment
          commentAbleType
          content
          companyId
          createdAt
          updatedAt
        }
        totalChildren
        companyId
        createdAt
        updatedAt
      }
    }
  }
`;
