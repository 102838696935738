import { DepositMethodEnum } from 'graphql/main/mutations';
import * as yup from 'yup';

export interface DepositForm {
  paymentAmount?: string;
  paymentMethod: DepositMethodEnum;
}
export const depositFormSchema = yup.object().shape({
  paymentAmount: yup
    .string()
    .required('Vui lòng nhập số tiền muốn nạp')
    .test('Over 10000', `Số cần cần nạp phải tối thiểu 1.000 VNĐ`, (value) => {
      if (!value) {
        return false;
      }
      const numericValue = parseFloat(value);
      return !isNaN(numericValue) && numericValue >= 10000;
    }),
});
