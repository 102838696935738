import { gql } from '@apollo/client';
import { FromToDate } from './get-b2c-users';
import { FileWithFullUrlsV2 } from './get-detail-contract-real-estate-user';
import { DateTime, ID, Int, ObjectID, RealEstate, String, UserType } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export enum GuestReportStatusEnum {
  PENDING = 'PENDING',
  DONE = 'DONE',
  LOCKED = 'LOCKED',
}
export interface GuestReportOutputType {
  id?: ID;
  fullName?: String;
  phone?: String;
  idNumber?: String;
  yearOfBirth?: String;
  address?: String;
  email?: String;
  time?: DateTime;
  description?: String;
  attachments?: FileWithFullUrlsV2[];
  createdBy?: UserType;
  updatedBy?: UserType;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  realEstate?: RealEstate;
  realEstateId?: ID;
  status?: GuestReportStatusEnum;
  approvedBy?: UserType;
  rejectedBy?: UserType;
  approvedAt?: DateTime;
  rejectedAt?: DateTime;
  code?: String;
  enableAction?: Boolean;
}
export interface ListGuestReport extends PaginationOf {
  items?: GuestReportOutputType[];
}

export interface GetListGuestReportATMNhaData {
  getListGuestReportATMNha?: ListGuestReport;
}
export interface GetListGuestReportATMNhaVariables {
  fullName?: String;
  phone?: String;
  email?: String;
  search?: String;
  address?: String;
  time?: FromToDate;
  code?: String;
  status?: GuestReportStatusEnum[];
  createdBy?: String;
  updatedBy?: String;
  rejectedBy?: String;
  approvedBy?: String;
  updatedAt?: FromToDate;
  createdAt?: FromToDate;
  province?: ObjectID;
  district?: ObjectID;
  ward?: ObjectID;
  street?: ObjectID;
  houseNumber?: String;
  page?: Int;
  limit?: Int;
}

export const GET_LIST_GUEST_REPORT_ATMNHA = gql`
  query getListGuestReportATMNha(
    $fullName: String
    $phone: String
    $email: String
    $search: String
    $address: String
    $time: FromToDate
    $code: String
    $status: [GuestReportStatusEnum]
    $createdBy: String
    $updatedBy: String
    $rejectedBy: String
    $approvedBy: String
    $updatedAt: FromToDate
    $createdAt: FromToDate
    $province: ObjectID
    $district: ObjectID
    $ward: ObjectID
    $street: ObjectID
    $houseNumber: String
    $page: Int
    $limit: Int
  ) @api(name: realEstate) {
    getListGuestReportATMNha(
      fullName: $fullName
      phone: $phone
      email: $email
      search: $search
      address: $address
      time: $time
      code: $code
      status: $status
      createdBy: $createdBy
      updatedBy: $updatedBy
      rejectedBy: $rejectedBy
      approvedBy: $approvedBy
      updatedAt: $updatedAt
      createdAt: $createdAt
      province: $province
      district: $district
      ward: $ward
      street: $street
      houseNumber: $houseNumber
      page: $page
      limit: $limit
    ) {
      hasNextPage
      hasPrevPage
      items {
        id
        code
        fullName
        status
        phone
        address
        email
        realEstate {
          houseNumber
          street {
            id
            name
          }
          ward {
            id
            name
          }
          district {
            id
            name
          }
          province {
            id
            name
          }
        }
        time
        createdBy {
          id
          fullName
        }
        createdAt
      }
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
