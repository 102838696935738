import { gql } from '@apollo/client';
import { FromToDate } from './get-b2c-users';
import { FileWithFullUrlsV2 } from './get-detail-contract-real-estate-user';
import {
  DateTime,
  Float,
  FromTo,
  ID,
  Int,
  ObjectID,
  RealEstate,
  String,
  UserType,
} from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export enum StatusContractRealEstateType {
  process = 'process',
  approve = 'approve',
  dispute = 'dispute',
  lock = 'lock',
}
export enum TypeCustomerType {
  enterprise = 'enterprise',
  individual = 'individual',
}
export interface ContractOutputType {
  id?: ID;
  code?: String;
  name?: String;
  description?: String;
  createdBy?: UserType;
  updatedBy?: UserType;
  createdAt?: DateTime;
  updatedAt?: DateTime;
}
export interface ContractRealEstateOutputType {
  id?: ID;
  fullName?: String;
  typeCustomer?: TypeCustomerType;
  phone?: String;
  contractType?: ContractOutputType;
  nameContract?: String;
  content?: String;
  contractDate?: DateTime;
  contractValue?: Float;
  startDate?: DateTime;
  endDate?: DateTime;
  status?: StatusContractRealEstateType;
  attachments?: FileWithFullUrlsV2[];
  code?: String;
  product?: RealEstate;
  createdBy?: UserType;
  updatedBy?: UserType;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  enableAction?: Boolean;
}
export interface ListContractRealEstate extends PaginationOf {
  items?: ContractRealEstateOutputType[];
}
export interface FilterListContractEstate {
  type?: String[];
  hasAlley?: Boolean;
  code?: String;
  fullName?: String;
  status?: StatusContractRealEstateType[];
  houseNumber?: String;
  contractId?: ObjectID;
  productId?: ObjectID;
  contractName?: String;
  contractValue?: FromTo;
  street?: ObjectID;
  ward?: ObjectID;
  district?: ObjectID;
  province?: ObjectID;
  createdBy?: String;
  updatedBy?: String;
  contractDate?: FromToDate;
  startDate?: FromToDate;
  endDate?: FromToDate;
  updatedAt?: FromToDate;
  createdAt?: FromToDate;
}

export interface GetListContractRealEstateUserData {
  getListContractRealEstateUser?: ListContractRealEstate;
}
export interface GetListContractRealEstateUserVariables {
  filter?: FilterListContractEstate;
  page?: Int;
  limit?: Int;
}

export const GET_LIST_CONTRACT_REAL_ESTATE_USER = gql`
  query getListContractRealEstateUser($filter: FilterListContractEstate, $page: Int, $limit: Int)
  @api(name: realEstate) {
    getListContractRealEstateUser(filter: $filter, page: $page, limit: $limit) {
      hasNextPage
      limit
      page
      prevPage
      total
      hasPrevPage
      nextPage
      pagingCounter
      totalPages
      items {
        id
        fullName
        typeCustomer
        phone
        contractType {
          id
          code
          name
          description
        }
        nameContract
        content
        contractDate
        contractValue
        startDate
        endDate
        status
        code
        product {
          id
          type
          realEstateId
          hasAlley
          fullAddress
          province {
            name
          }
          district {
            name
          }
          ward {
            name
          }
          street {
            name
          }
          houseNumber
          price
        }
        createdBy {
          fullName
        }
        updatedBy {
          fullName
        }
        createdAt
        updatedAt
      }
    }
  }
`;
