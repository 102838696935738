import { SVGProps } from 'react';

const IconMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.875 9.625H6.88417M11 9.625H11.0092M15.125 9.625H15.1342M6.41667 16.5V18.6409C6.41667 19.1293 6.41667 19.3735 6.51679 19.499C6.60387 19.6081 6.73591 19.6715 6.87549 19.6714C7.03599 19.6712 7.2267 19.5186 7.60811 19.2135L9.79478 17.4642C10.2415 17.1068 10.4648 16.9281 10.7135 16.8011C10.9342 16.6884 11.1691 16.606 11.4118 16.5561C11.6854 16.5 11.9714 16.5 12.5434 16.5H14.85C16.3901 16.5 17.1602 16.5 17.7485 16.2003C18.2659 15.9366 18.6866 15.5159 18.9503 14.9985C19.25 14.4102 19.25 13.6401 19.25 12.1V7.15C19.25 5.60986 19.25 4.83978 18.9503 4.25153C18.6866 3.73408 18.2659 3.31338 17.7485 3.04973C17.1602 2.75 16.3901 2.75 14.85 2.75H7.15C5.60986 2.75 4.83978 2.75 4.25153 3.04973C3.73408 3.31338 3.31338 3.73408 3.04973 4.25153C2.75 4.83978 2.75 5.60986 2.75 7.15V12.8333C2.75 13.6858 2.75 14.112 2.8437 14.4618C3.09799 15.4108 3.83924 16.152 4.78825 16.4063C5.13796 16.5 5.56419 16.5 6.41667 16.5ZM7.33333 9.625C7.33333 9.87813 7.12813 10.0833 6.875 10.0833C6.62187 10.0833 6.41667 9.87813 6.41667 9.625C6.41667 9.37187 6.62187 9.16667 6.875 9.16667C7.12813 9.16667 7.33333 9.37187 7.33333 9.625ZM11.4583 9.625C11.4583 9.87813 11.2531 10.0833 11 10.0833C10.7469 10.0833 10.5417 9.87813 10.5417 9.625C10.5417 9.37187 10.7469 9.16667 11 9.16667C11.2531 9.16667 11.4583 9.37187 11.4583 9.625ZM15.5833 9.625C15.5833 9.87813 15.3781 10.0833 15.125 10.0833C14.8719 10.0833 14.6667 9.87813 14.6667 9.625C14.6667 9.37187 14.8719 9.16667 15.125 9.16667C15.3781 9.16667 15.5833 9.37187 15.5833 9.625Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { IconMessage };
