import { gql } from '@apollo/client';
import { FromToDate } from './get-b2c-users';
import {
  ApprovalStatusEnumType,
  Direction,
  FromTo,
  Int,
  ListRealEstate,
  ObjectID,
  PostStatusType,
  String,
  TradingStatus,
} from './get-real-estates';
import { RealEstateTypeEnumType } from './slugery';

export enum LeadRealEstateStatus {
  onSell = 'onSell',
  sold = 'sold',
  inDispute = 'inDispute',
  locked = 'locked',
}

export interface GetListLeadRealEstatesData {
  getListLeadRealEstates?: ListRealEstate;
}
export interface GetListLeadRealEstatesVariables {
  searchString?: String;
  approvalStatus?: ApprovalStatusEnumType[];
  hasAlley?: Boolean;
  bankName?: String;
  length?: FromTo;
  width?: FromTo;
  pricePerSqMeter?: FromTo;
  rear?: FromTo;
  floorCount?: FromTo;
  frontHomeAlleyWidth?: FromTo;
  isForSell?: Boolean;
  bedroomCount?: FromTo;
  direction?: Direction[];
  area?: FromTo;
  clearanceArea?: FromTo;
  type?: RealEstateTypeEnumType[];
  toiletCount?: FromTo;
  commission?: String;
  constructionArea?: FromTo;
  province?: ObjectID;
  district?: ObjectID;
  ward?: ObjectID;
  street?: ObjectID;
  houseNumber?: String;
  price?: FromTo;
  pageNo?: String;
  parcel?: String;
  curatorName?: String;
  curatorPhone?: String;
  createdAt?: FromToDate;
  updatedAt?: FromToDate;
  editor?: String;
  approvedAt?: FromToDate;
  approvedBy?: String;
  creator?: String;
  realEstateId?: String;
  tradingStatus?: TradingStatus[];
  postStatus?: PostStatusType[];
  leadREStatus?: LeadRealEstateStatus[];
  sort?: String;
  page?: Int;
  limit?: Int;
}

export const GET_LIST_LEAD_REAL_ESTATES = gql`
  query getListLeadRealEstates(
    $searchString: String
    $approvalStatus: [ApprovalStatusEnumType]
    $hasAlley: Boolean
    $bankName: String
    $length: FromTo
    $width: FromTo
    $pricePerSqMeter: FromTo
    $rear: FromTo
    $floorCount: FromTo
    $frontHomeAlleyWidth: FromTo
    $isForSell: Boolean
    $bedroomCount: FromTo
    $direction: [Direction]
    $area: FromTo
    $clearanceArea: FromTo
    $type: [RealEstateTypeEnumType]
    $toiletCount: FromTo
    $commission: String
    $constructionArea: FromTo
    $province: ObjectID
    $district: ObjectID
    $ward: ObjectID
    $street: ObjectID
    $houseNumber: String
    $price: FromTo
    $pageNo: String
    $parcel: String
    $curatorName: String
    $curatorPhone: String
    $createdAt: FromToDate
    $updatedAt: FromToDate
    $editor: String
    $approvedAt: FromToDate
    $approvedBy: String
    $creator: String
    $realEstateId: String
    $tradingStatus: [TradingStatus]
    $postStatus: [PostStatusType]
    $leadREStatus: [LeadRealEstateStatus]
    $sort: String
    $page: Int
    $limit: Int
  ) @api(name: realEstate) {
    getListLeadRealEstates(
      searchString: $searchString
      approvalStatus: $approvalStatus
      hasAlley: $hasAlley
      bankName: $bankName
      length: $length
      width: $width
      pricePerSqMeter: $pricePerSqMeter
      rear: $rear
      floorCount: $floorCount
      frontHomeAlleyWidth: $frontHomeAlleyWidth
      isForSell: $isForSell
      bedroomCount: $bedroomCount
      direction: $direction
      area: $area
      clearanceArea: $clearanceArea
      type: $type
      toiletCount: $toiletCount
      commission: $commission
      constructionArea: $constructionArea
      province: $province
      district: $district
      ward: $ward
      street: $street
      houseNumber: $houseNumber
      price: $price
      pageNo: $pageNo
      parcel: $parcel
      curatorName: $curatorName
      curatorPhone: $curatorPhone
      createdAt: $createdAt
      updatedAt: $updatedAt
      editor: $editor
      approvedAt: $approvedAt
      approvedBy: $approvedBy
      creator: $creator
      realEstateId: $realEstateId
      tradingStatus: $tradingStatus
      postStatus: $postStatus
      leadREStatus: $leadREStatus
      sort: $sort
      page: $page
      limit: $limit
    ) {
      items {
        id
        postTitle
        postContent
        isForSell
        realEstateType
        demandEstate
        isSaved
        saveItem {
          id
          belongsToCollection {
            id
            type
            name
            owner
            totalItems
          }
        }
        area
        floorCount
        toiletCount
        bedroomCount
        isAuthorizedRe
        isForeclosure
        price
        createdAt
        slug
        fullSlug
        postType
        fullAddress
        shortAddress
        privateNote
        province {
          id
          name
        }
        mediaUrls {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        creator {
          id
        }
        approvalStatus
        postStatus
        tradingStatus
        leadREStatus
      }
    }
  }
`;
