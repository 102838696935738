import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  fillColor1?: string;
  fillColor2?: string;
  r?: string;
}

const IconSavedLeadRealEstateModule = (props: CustomSVGProps) => {
  const { fillColor1, fillColor2, r, ...restProps } = props;

  return (
    <svg
      width='55'
      height='55'
      viewBox='0 0 55 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g clipPath='url(#clip0_161_31023)'>
        <rect width='55' height='55' rx='8' fill='white' />
        <rect width='55' height='55' rx='8' fill='url(#paint0_linear_161_31023)' />
        <rect width='55' height='55' rx='8' fill={fillColor1 ?? '#ECECEC'} />
        <circle
          opacity='0.6'
          cx='44.6111'
          cy='48.5'
          r='30'
          fill='url(#paint1_radial_161_31023)'
          fillOpacity='0.8'
        />
        <g clipPath='url(#clip1_161_31017)'>
          <path
            d='M29.5605 17.75C29.2792 17.4688 28.8977 17.3108 28.5 17.3108C28.1022 17.3108 27.7208 17.4688 27.4395 17.75L17.469 27.719C17.3992 27.7887 17.3439 27.8715 17.3062 27.9626C17.2684 28.0537 17.249 28.1514 17.249 28.25C17.249 28.3486 17.2684 28.4462 17.3062 28.5373C17.3439 28.6285 17.3992 28.7112 17.469 28.781C17.6098 28.9218 17.8008 29.0009 18 29.0009C18.0986 29.0009 18.1962 28.9815 18.2873 28.9438C18.3785 28.906 18.4612 28.8507 18.531 28.781L28.5 18.8105L38.469 28.781C38.6098 28.9218 38.8008 29.0009 39 29.0009C39.1991 29.0009 39.3901 28.9218 39.531 28.781C39.6718 28.6401 39.7509 28.4491 39.7509 28.25C39.7509 28.0508 39.6718 27.8598 39.531 27.719L36 24.1895V19.25C36 19.0511 35.921 18.8603 35.7803 18.7196C35.6397 18.579 35.4489 18.5 35.25 18.5H33.75C33.5511 18.5 33.3603 18.579 33.2196 18.7196C33.079 18.8603 33 19.0511 33 19.25V21.1895L29.5605 17.75Z'
            fill={fillColor2 ?? '#80888F'}
          />
          <path
            d='M28.5 20.4395L35.568 27.5075C34.32 27.4485 33.0801 27.7371 31.9864 28.341C30.8927 28.945 29.9881 29.8407 29.3733 30.9284C28.7585 32.016 28.4576 33.253 28.5042 34.5015C28.5507 35.75 28.9429 36.9611 29.637 38H21.75C21.1533 38 20.581 37.7629 20.159 37.3409C19.7371 36.919 19.5 36.3467 19.5 35.75V29.4395L28.5 20.4395Z'
            fill={fillColor2 ?? '#80888F'}
          />
          <path
            d='M35.25 39.5C36.6424 39.5 37.9777 38.9469 38.9623 37.9623C39.9469 36.9777 40.5 35.6424 40.5 34.25C40.5 32.8576 39.9469 31.5223 38.9623 30.5377C37.9777 29.5531 36.6424 29 35.25 29C33.8576 29 32.5223 29.5531 31.5377 30.5377C30.5531 31.5223 30 32.8576 30 34.25C30 35.6424 30.5531 36.9777 31.5377 37.9623C32.5223 38.9469 33.8576 39.5 35.25 39.5ZM37.7685 32.7605L35.766 36.0995C35.678 36.2462 35.5577 36.3709 35.4143 36.4641C35.2708 36.5573 35.108 36.6166 34.9382 36.6374C34.7684 36.6582 34.5961 36.6401 34.4344 36.5843C34.2727 36.5285 34.1258 36.4366 34.005 36.3155L32.844 35.156C32.7034 35.0152 32.6244 34.8242 32.6246 34.6252C32.6247 34.4262 32.7039 34.2354 32.8447 34.0947C32.9856 33.9541 33.1765 33.8752 33.3755 33.8753C33.5746 33.8755 33.7654 33.9547 33.906 34.0955L34.7265 34.916L36.4815 31.9895C36.5321 31.905 36.5989 31.8313 36.678 31.7726C36.7571 31.7139 36.847 31.6713 36.9426 31.6474C37.0381 31.6234 37.1375 31.6185 37.2349 31.633C37.3323 31.6474 37.426 31.6809 37.5105 31.7315C37.595 31.7821 37.6687 31.8489 37.7274 31.928C37.7861 32.0071 37.8287 32.097 37.8526 32.1926C37.8766 32.2881 37.8815 32.3875 37.867 32.4849C37.8526 32.5823 37.8191 32.676 37.7685 32.7605Z'
            fill={fillColor2 ?? '#80888F'}
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_161_31023'
          x1='50.1111'
          y1='51.9444'
          x2='4.88888'
          y2='4.27777'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9DBFE8' />
          <stop offset='1' stopColor='#97A9B0' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_161_31023'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(44.6111 48.5) rotate(90) scale(30)'
        >
          <stop stopColor='white' stopOpacity='0.2' />
          <stop offset='0.890625' stopColor='white' stopOpacity='0.83' />
        </radialGradient>
        <clipPath id='clip0_161_31023'>
          <rect width='55' height='55' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IconSavedLeadRealEstateModule };
