import { gql } from '@apollo/client';
import { ContractRealEstateOutputType } from './get-list-contract-real-estate-user';
import { DateTime, ID, Int, ObjectID, String, Url } from './get-real-estates';

export interface Creator {
  id?: ObjectID;
  fullName?: String;
  phones?: String[];
  phone?: String;
  secondPhone?: String;
  avatar?: FileWithFullUrlsV2;
  email?: String;
  employeeCode?: String;
}
export interface FileWithFullUrlsV2 {
  fileId?: ID;
  originalUrl?: String;
  type?: String;
  urls?: Url[];
  creator?: Creator;
  createdAt?: DateTime;
  updatedAt?: DateTime;
  size?: Int;
  name?: String;
}

export interface GetDetailContractRealEstateUserData {
  getDetailContractRealEstateUser?: ContractRealEstateOutputType;
}
export interface GetDetailContractRealEstateUserVariables {
  id: ObjectID;
}

export const GET_DETAIL_CONTRACT_REAL_ESTATE_USER = gql`
  query getDetailContractRealEstateUser($id: ObjectID!) @api(name: realEstate) {
    getDetailContractRealEstateUser(id: $id) {
      id
      code
      fullName
      typeCustomer
      phone
      contractType {
        id
        code
        name
        description
        createdAt
        updatedAt
      }
      product {
        id
        type
        fullSlug
        hasAlley
        province {
          id
          name
        }
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
        houseNumber
        price
        area
        width
        length
        direction
        bedroomCount
        toiletCount
        floorCount
        fullAddress
      }
      nameContract
      content
      contractDate
      contractValue
      startDate
      endDate
      status
      createdAt
      attachments {
        fileId
        type
        originalUrl
        createdAt
        size
        name
      }
      createdBy {
        fullName
        phone
        email
      }
      updatedAt
      enableAction
    }
  }
`;
