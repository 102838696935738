import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import {
  ContractRealEstateOutputType,
  Float,
  ObjectID,
  String,
  TypeCustomerType,
} from '../queries';
import { Date } from './update-user-v2';

export interface CreateContractRealEstateInputType {
  fullName: String;
  typeCustomer: TypeCustomerType;
  phone: String;
  contractId?: ObjectID;
  nameContract: String;
  content?: String;
  contractDate?: Date;
  contractValue?: Float;
  startDate?: Date;
  endDate?: Date;
  attachments?: String[];
  productId?: ObjectID;
}
export interface ContractRealEstateOutputMutation extends MutationOf {
  payload?: ContractRealEstateOutputType;
}

export interface CreateContractRealEstateData {
  createContractRealEstate?: ContractRealEstateOutputMutation;
}
export interface CreateContractRealEstateVariables {
  input?: CreateContractRealEstateInputType;
}

export const CREATE_CONTRACT_REAL_ESTATE = gql`
  mutation createContractRealEstate($input: CreateContractRealEstateInputType)
  @api(name: realEstate) {
    createContractRealEstate(input: $input) {
      success
      msg
      payload {
        id
      }
    }
  }
`;
