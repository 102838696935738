import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationOfString } from './verify-otp';

export enum NameCardTypeEnum {
  facebook = 'facebook',
  messenger = 'messenger',
  gmail = 'gmail',
  tiktok = 'tiktok',
  whatsapp = 'whatsapp',
  zalo = 'zalo',
  viber = 'viber',
}

export interface AddNameCardData {
  addNameCard?: MutationOfString;
}
export interface AddNameCardVariables {
  userId: String;
  type: NameCardTypeEnum;
  value: String;
}

export const ADD_NAME_CARD = gql`
  mutation addNameCard($userId: String!, $type: NameCardTypeEnum!, $value: String!)
  @api(name: users) {
    addNameCard(userId: $userId, type: $type, value: $value) {
      payload
      success
      msg
    }
  }
`;
