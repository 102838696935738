import { gql } from '@apollo/client';
import { MutationOf } from 'graphql/upload/mutations';
import { String } from '../queries';
import { GuestReportOutputType } from '../queries/get-list-guest-report-atmnha';
import { Date } from './update-user-v2';

export interface CreateGuestReportATMNhaInputType {
  fullName: String;
  phone: String;
  idNumber: String;
  yearOfBirth?: String;
  address?: String;
  email?: String;
  time?: Date;
  description?: String;
  attachments?: String[];
  realEstateId: String;
}
export interface GuestReportOutputMutation extends MutationOf {
  payload?: GuestReportOutputType;
}
export interface CreateGuestReportATMNhaData {
  createGuestReportATMNha?: GuestReportOutputMutation;
}
export interface CreateGuestReportATMNhaVariables {
  input?: CreateGuestReportATMNhaInputType;
}

export const CREATE_GUEST_REPORT_ATMNHA = gql`
  mutation createGuestReportATMNha($input: CreateGuestReportATMNhaInputType)
  @api(name: realEstate) {
    createGuestReportATMNha(input: $input) {
      success
      payload {
        id
      }
    }
  }
`;
