import { gql } from '@apollo/client';
import { GuestReportOutputType } from './get-list-guest-report-atmnha';
import { ObjectID } from './get-real-estates';

export interface GetDetailGuestReportATMNhaData {
  getDetailGuestReportATMNha?: GuestReportOutputType;
}
export interface GetDetailGuestReportATMNhaVariables {
  id: ObjectID;
}

export const GET_DETAIL_GUEST_REPORT_ATMNHA = gql`
  query getDetailGuestReportATMNha($id: ObjectID!) @api(name: realEstate) {
    getDetailGuestReportATMNha(id: $id) {
      id
      code
      fullName
      phone
      email
      idNumber
      yearOfBirth
      address
      time
      description
      status
      attachments {
        fileId
        type
        originalUrl
        createdAt
        size
        name
      }
      realEstate {
        id
        type
        fullSlug
        hasAlley
        houseNumber
        postTitle
        mediaUrls {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
        }
        isForSell
        postType
        isAuthorizedRe
        isForeclosure
        fullAddress
        area
        type
        leadREStatus
        isLeadRE
        bedroomCount
        toiletCount
        province {
          id
          name
        }
        district {
          id
          name
        }
        ward {
          id
          name
        }
        street {
          id
          name
        }
        price
      }
      createdBy {
        id
        fullName
        phone
        email
      }
      enableAction
    }
  }
`;
