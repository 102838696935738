import { gql } from '@apollo/client';
import { ObjectID, RealEstate, String } from './get-real-estates';

export interface GetRealEstateDetailsData {
  getRealEstateDetails?: RealEstate;
}
export interface GetRealEstateDetailsVariables {
  realEstateId?: ObjectID;
  slug?: String;
  id?: String;
}

export const GET_REAL_ESTATE_DETAILS = gql`
  query getRealEstateDetails($realEstateId: ObjectID, $slug: String, $id: String)
  @api(name: realEstate) {
    getRealEstateDetails(realEstateId: $realEstateId, slug: $slug, id: $id) {
      id
      realEstateId
      type
      fullSlug
      creator {
        id
        fullName
        type
        province {
          id
          name
        }
        avatar {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
        }
      }
      contactPhones
      contactName
      shortAddress
      demandEstate
      mediaUrls {
        ... on FileWithFullUrls {
          fileId
          type
          originalUrl
        }
        ... on YoutubeFile {
          fileId
          type
          youtubeMetadata {
            videoId
            thumbnailUrl
          }
        }
      }
      panoramicImages {
        ... on FileWithFullUrls {
          fileId
          type
          originalUrl
        }
        ... on YoutubeFile {
          fileId
          type
          youtubeMetadata {
            videoId
            thumbnailUrl
          }
        }
      }
      vr360Urls
      breadScrumbs {
        tooltip
        name
        slug
      }
      province {
        id
        name
      }
      district {
        id
        name
      }
      ward {
        id
        name
      }
      street {
        id
        name
      }
      postTitle
      postContent
      privateNote
      price
      fullAddress
      area
      floorCount
      width
      length
      realEstateId
      realEstateType
      isSaved
      saveItem {
        id
        belongsToCollection {
          id
          type
          name
          owner
          totalItems
        }
      }
      isAuthorizedRe
      isForeclosure
      isLeadRE
      leadREStatus
      contractCount
      guestReportCount
      enableAction
      postType
      createdAt
      map {
        coordinates
        type
      }
      fixedUtilities
      utilities
      localUtilities
      villaView
      equipmentsAndServices
      legals
      isForSell
      hasAlley
      alleyTurnCount
      frontHomeAlleyWidth
      rear
      constructionArea
      bedroomCount
      toiletCount
      direction
      isNewConstruction
      industryTrade
      project {
        id
        name
        title
      }
      buildingType
      hotelStar
      warehouseType
      block
      investor
      onFloor
      clearanceArea
      hasMezzanine
      endShowTime
      approvalStatus
      tradingStatus
      numberOfRemaining
      houseNumber
      farmLandUse
      contactEmail
      pageNo
      parcel
      contactInfo {
        id
        fullName
        type
        province {
          id
          name
        }
        avatar {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
        }
        phone
      }
    }
  }
`;
