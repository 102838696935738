import { mapClient } from 'apollo/client';
import { GET_LIST_PROVINCES, GetListProvincesData, Province } from 'graphql/map/queries';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

interface Props {
  data?: Province[];
}

const ListProvinceContext = createContext<Props>({});

export const useListProvinceContext = () => useContext(ListProvinceContext);

const ListProvinceProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<Province[]>();

  useEffect(() => {
    (async () => {
      try {
        const { data: getListProvincesData } = await mapClient.query<GetListProvincesData>({
          query: GET_LIST_PROVINCES,
        });
        setData(getListProvincesData.getListProvinces);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return <ListProvinceContext.Provider value={{ data }}>{children}</ListProvinceContext.Provider>;
};

export default ListProvinceProvider;
