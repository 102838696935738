import { mainClient } from 'apollo/client';
import {
  GET_REAL_ESTATE_CONFIGURATIONS,
  GetRealEstateConfigurationsData,
  RealEstateConfiguration,
} from 'graphql/main/queries';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

interface Props {
  data?: RealEstateConfiguration;
}

const RealEstateConfigurationContext = createContext<Props>({});

export const useRealEstateConfigurationContext = () => useContext(RealEstateConfigurationContext);

const RealEstateConfigurationProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<RealEstateConfiguration>();

  useEffect(() => {
    (async () => {
      try {
        const { data: getRealEstateConfigurationsData } =
          await mainClient.query<GetRealEstateConfigurationsData>({
            query: GET_REAL_ESTATE_CONFIGURATIONS,
          });
        setData(getRealEstateConfigurationsData.getRealEstateConfigurations);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <RealEstateConfigurationContext.Provider value={{ data }}>
      {children}
    </RealEstateConfigurationContext.Provider>
  );
};

export default RealEstateConfigurationProvider;
