import { gql } from '@apollo/client';
import { NameCardTypeEnum } from '../mutations';
import { DateTime, Float, Int, ObjectID, String } from './get-real-estates';
import { AddressType, AnotherServiceFileType, CompanyUsers } from './list-companies-by-type';
import { Rank } from './list-request-upgrade-by-user';

export interface NeedUpdateFieldType {
  field?: String;
  reason?: String;
}
export interface CompanyRequestOutputType {
  createdAt?: DateTime;
  creator?: String;
  id?: String;
  needUpdateFields?: NeedUpdateFieldType;
  rejectedReason?: String;
  status?: String;
  updatedAt?: DateTime;
}
export interface UserConfigType {
  defaultWallet?: String;
}
export interface RefUserType {
  avatar?: AnotherServiceFileType;
  birthDate?: String;
  companyFields?: String[];
  createdAt?: DateTime;
  developer?: String;
  email?: String;
  fullName?: String;
  gender?: String;
  id?: String;
  madeCommissions?: Float;
  memberId?: String;
  phone?: String;
  phones?: String[];
  province?: AddressType;
  taxId?: String;
  type?: String;
  updatedAt?: DateTime;
}
export interface PremiumRequestType {
  createdAt?: DateTime;
  creator?: String;
  developer?: RefUserType;
  id?: String;
  modifiedUser?: B2CUser;
  needUpdateFields?: NeedUpdateFieldType[];
  rejectedReason?: String;
  status?: String;
  updatedAt?: DateTime;
  user?: B2CUser;
}
export interface NameCardType {
  id?: String;
  type?: NameCardTypeEnum;
  iconUrl?: String;
  value?: String;
}
export interface B2CUser {
  namecards?: NameCardType[];
  attachments?: ObjectID[];
  avatar?: AnotherServiceFileType;
  backIdImages?: ObjectID[];
  bank?: String;
  bankAccountNumber?: String;
  company?: String;
  companyFields?: String[];
  companyLicense?: String[];
  companyName?: String;
  companyRef?: CompanyUsers;
  companyRegisterRequest?: CompanyRequestOutputType;
  configs?: UserConfigType;
  createdAt?: DateTime;
  dateOfBirth?: DateTime;
  description?: String;
  developer?: String;
  developerRef?: B2CUser;
  developMember?: RefUserType;
  deviceToken?: String;
  email?: String;
  establishedDate?: DateTime;
  facebook?: String;
  firstName?: String;
  frequentREActivities?: String[];
  frequentREProvinces?: String[];
  frontIdImages?: ObjectID[];
  fullName?: String;
  gender?: String;
  id?: String;
  idNumber?: String;
  isCompanyApproved?: Boolean;
  isLocked?: Boolean;
  isPremiumAccount?: Boolean;
  isShareInfo?: Boolean;
  isVerifiedEmail?: Boolean;
  isVerifiedPhone?: Boolean;
  lastName?: String;
  lastOnline?: DateTime;
  madeCommissions?: Float;
  mediaOfCompany?: String[];
  memberId?: String;
  phone?: String;
  phoneChanged?: Boolean;
  phones?: String[];
  province?: AddressType;
  rank?: Rank;
  refMembers?: B2CUser;
  rejectedReason?: String;
  rejectedReasonCompany?: String;
  role?: String;
  secondPhone?: String;
  secondPhoneChanged?: Boolean;
  seenPremiumNoti?: Boolean;
  sendProfileAt?: DateTime;
  shareOwnedPosts?: Boolean;
  signupType?: String;
  skype?: String;
  taxId?: String;
  type?: String;
  updatedAt?: DateTime;
  upgradePremiumDate?: DateTime;
  upgradePremiumRequest?: PremiumRequestType;
  userType?: String;
  viber?: String;
  zalo?: String;
  ecommerceData?: EcommerceData;
}

export interface EcommerceData {
  token?: String;
  userId?: Int;
}
export interface GetRepresentativeBrokersData {
  getRepresentativeBrokers?: B2CUser[];
}
export interface GetRepresentativeBrokersVariables {
  limit?: Int;
}

export const GET_REPRESENTATIVE_BROKERS = gql`
  query getRepresentativeBrokers($limit: Int) @api(name: users) {
    getRepresentativeBrokers(limit: $limit) {
      id
      fullName
      phone
      secondPhone
      phones
      avatar {
        ... on FileWithFullUrls {
          fileId
          type
          originalUrl
        }
        ... on YoutubeFile {
          fileId
          type
          youtubeMetadata {
            videoId
          }
        }
      }
      frequentREProvinces
    }
  }
`;
