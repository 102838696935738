import { NAME_REGEX, PHONE_NUMBER_REGEX, VIETNAMESE_ID_NUMBER_REGEX } from 'globalConstants';
import { gte, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import * as yup from 'yup';

export interface GuestReportForm {
  fullName?: string;
  phone?: string;
  idNumber?: string;
  yearOfBirth?: string;
  address?: string;
  email?: string;
  time?: string;
  description?: string;
  attachments?: string[];
}

export const guestReportFormDefaultValues: GuestReportForm = {
  fullName: undefined,
  phone: undefined,
  email: undefined,
  description: undefined,
  idNumber: undefined,
  address: undefined,
  attachments: undefined,
  time: undefined,
  yearOfBirth: undefined,
};

export const guestReportFormSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('Vui lòng nhập họ và tên')
    .matches(NAME_REGEX, 'Vui lòng nhập họ và tên hợp lệ'),
  phone: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  email: yup.string().nullable().email('Vui lòng nhập đúng định dạng email@xxx.com'),
  idNumber: yup
    .string()
    .required('Vui lòng nhập Số CMND/CCCD')
    .matches(VIETNAMESE_ID_NUMBER_REGEX, 'Vui lòng nhập số CMND/CCCD hợp lệ'),
  yearOfBirth: yup
    .string()
    .nullable()
    .test(
      'DOB',
      'Vui lòng chọn năm sinh từ 18 tuổi trở lên',
      (date) => isEmpty(date) || gte(moment().diff(moment(date).format('YYYY'), 'years'), 18),
    ),
});
