import { gql } from '@apollo/client';
import { String } from './get-real-estates';
import { B2CUser } from './get-representative-brokers';

export interface GetUserNameCardData {
  getUserNameCard?: B2CUser;
}
export interface GetUserNameCardVariables {
  userId?: String;
  memberId?: String;
}

export const GET_USER_NAME_CARD = gql`
  query getUserNameCard($userId: String, $memberId: String) @api(name: users) {
    getUserNameCard(userId: $userId, memberId: $memberId) {
      id
      memberId
      type
      fullName
      province {
        name
        id
      }
      avatar {
        ... on FileWithFullUrls {
          fileId
          type
          originalUrl
        }
      }
      phones
      namecards {
        id
        type
        iconUrl
        value
      }
    }
  }
`;
