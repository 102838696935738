import { gql } from '@apollo/client';
import { Boolean, Float, RangeValue, String } from './get-real-estates';

export interface KeyValue {
  estateType?: String[];
  fee?: Float;
  isForRent?: Boolean;
  isForSell?: Boolean;
  key?: String;
  sale?: Boolean;
  value?: String;
}
export interface RealEstateParameter {
  alleyWidth?: RangeValue[];
  area?: RangeValue[];
  bedroomArea?: RangeValue[];
  bedroomCount?: RangeValue[];
  blockCount?: RangeValue[];
  buildingType?: RangeValue[];
  floorCount?: RangeValue[];
  floorPosition?: RangeValue[];
  hotelStar?: RangeValue[];
  length?: RangeValue[];
  priceToBuy?: RangeValue[];
  priceToRent?: RangeValue[];
  priceToRentByDay?: RangeValue[];
  priceToRentByMonth?: RangeValue[];
  roomCount?: RangeValue[];
  timeForRentUnits?: RangeValue[];
  timeToRent?: RangeValue[];
  toiletCount?: RangeValue[];
  villaView?: RangeValue[];
  width?: RangeValue[];
}
export interface ForSellAndRentREConfiguration {
  area?: RangeValue[];
  b2cUserTypes?: KeyValue[];
  bedroomCount?: RangeValue[];
  collectionTypes?: KeyValue[];
  constructionArea?: RangeValue[];
  directions?: KeyValue[];
  equipmentsAndServices?: KeyValue[];
  fixedUtilities?: KeyValue[];
  floorPosition?: RangeValue[];
  forRentRealEstateTypes?: KeyValue[];
  forSellRealEstateTypes?: KeyValue[];
  legals?: KeyValue[];
  length?: RangeValue[];
  localUtilities?: KeyValue[];
  mediaTypes?: KeyValue[];
  postTypes?: KeyValue[];
  priceForRent?: RangeValue[];
  priceForSell?: RangeValue[];
  priceUnitsForRent?: KeyValue[];
  priceUnitsForSell?: KeyValue[];
  progress?: RangeValue[];
  projectApartmentCount?: RangeValue[];
  projectAreaType?: KeyValue[];
  projectForRentTypes?: KeyValue[];
  projectForSaleTypes?: KeyValue[];
  projectLegals?: KeyValue[];
  projectSubTypes?: KeyValue[];
  projectViews?: KeyValue[];
  rear?: RangeValue[];
  timeForRentUnits?: KeyValue[];
  toiletCount?: RangeValue[];
  utilities?: KeyValue[];
  villaViewTypes?: KeyValue[];
  warehouseType?: KeyValue[];
  width?: RangeValue[];
  farmLandUse?: KeyValue[];
  upgradeRequestStatusType?: KeyValue[];
  upgradeRequestRankType?: KeyValue[];
  paymentStatusType?: KeyValue[];
  paymentAmountType?: KeyValue[];
  paymentMethodType?: KeyValue[];
  paymentType?: KeyValue[];
  statusContractRealEstateATMNhaConf?: KeyValue[];
  statusContractRealEstateBihaConf?: KeyValue[];
  guestReportStatusConf?: KeyValue[];
  leadREStatus?: [KeyValue];
}
export interface RealEstateFilterType {
  apartment?: RealEstateParameter;
  building?: RealEstateParameter;
  common?: RealEstateParameter;
  condotel?: RealEstateParameter;
  hotel?: RealEstateParameter;
  land?: RealEstateParameter;
  motel?: RealEstateParameter;
  penthouse?: RealEstateParameter;
  project?: RealEstateParameter;
  shophouse?: RealEstateParameter;
  townhouse?: RealEstateParameter;
  villa?: RealEstateParameter;
  warehouse?: RealEstateParameter;
}
export interface RealEstateType {
  apartment?: RealEstateParameter;
  building?: RealEstateParameter;
  hotel?: RealEstateParameter;
  land?: RealEstateParameter;
  motel?: RealEstateParameter;
  penthouse?: RealEstateParameter;
  project?: RealEstateParameter;
  shophouse?: RealEstateParameter;
  townhouse?: RealEstateParameter;
  villa?: RealEstateParameter;
  warehouse?: RealEstateParameter;
}
export interface RealEstateValuationConfiguration {
  constructionUtilities?: KeyValue[];
  constructQualities?: KeyValue[];
  fee?: Float;
  minusPoints?: KeyValue[];
  plusPoints?: KeyValue[];
  vat?: Float;
  landUsage?: [KeyValue];
  buildingUtility?: [KeyValue];
}
export interface RealEstateConfiguration {
  common?: ForSellAndRentREConfiguration;
  filter?: RealEstateFilterType;
  neededRealEstate?: RealEstateType;
  valuation?: RealEstateValuationConfiguration;
}

export interface GetRealEstateConfigurationsData {
  getRealEstateConfigurations?: RealEstateConfiguration;
}

export const GET_REAL_ESTATE_CONFIGURATIONS = gql`
  query getRealEstateConfigurations @api(name: realEstate) {
    getRealEstateConfigurations {
      common {
        b2cUserTypes {
          key
        }
        width {
          key
          from
          to
        }
        length {
          key
          from
          to
        }
        area {
          key
          from
          to
        }
        bedroomCount {
          key
          from
          to
        }
        toiletCount {
          key
          from
          to
        }
        postTypes {
          key
        }
        priceForRent {
          key
          from
          to
        }
        priceForSell {
          key
          from
          to
        }
        forSellRealEstateTypes {
          key
        }
        forRentRealEstateTypes {
          key
        }
        directions {
          key
        }
        warehouseType {
          key
        }
        fixedUtilities {
          key
        }
        utilities {
          key
        }
        localUtilities {
          key
        }
        villaViewTypes {
          key
        }
        equipmentsAndServices {
          key
        }
        legals {
          key
        }
        farmLandUse {
          key
        }
        upgradeRequestStatusType {
          key
        }
        upgradeRequestRankType {
          key
        }
        paymentStatusType {
          key
        }
        paymentAmountType {
          key
        }
        paymentMethodType {
          key
        }
        paymentType {
          key
        }
        statusContractRealEstateATMNhaConf {
          key
          value
        }
        statusContractRealEstateBihaConf {
          key
          value
        }
        guestReportStatusConf {
          key
          value
        }
        leadREStatus {
          key
          value
        }
      }
      filter {
        common {
          floorCount {
            key
            from
            to
          }
          hotelStar {
            key
          }
        }
        building {
          buildingType {
            key
          }
        }
      }
      valuation {
        buildingUtility {
          key
        }
        constructQualities {
          key
        }
        constructionUtilities {
          key
        }
        plusPoints {
          key
        }
        minusPoints {
          key
        }
        landUsage {
          key
        }
      }
    }
  }
`;
