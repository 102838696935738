import { gql } from '@apollo/client';
import { TypeOfDemandEnumType } from 'graphql/map/queries/get-suggestions';
import { PaginationOf } from './get-user-saved-real-estate';
import { FarmLandUseEnumType, RealEstateTypeEnumType } from './slugery';
import { BreadScrumb } from './slugify';

export enum UserTypeEnum {
  Broker = 'Broker',
  Company = 'Company',
  HomeOwner = 'HomeOwner',
  Employee = 'Employee',
  Student = 'Student',
}
export enum GenderEnumType {
  male = 'male',
  female = 'female',
  other = 'other',
}
export type JSONObject = object;
export type ID = string;
export type ObjectID = string;
export type String = string;
export type Int = number;
export type BigInt = number;
export type Float = number;
export type Boolean = boolean;
export type DateTime = string;
export interface FromToDateInput {
  from?: DateTime;
  to?: DateTime;
}
export interface FromTo {
  key?: String;
  operator?: String;
  from?: Float | null;
  to?: Float | null;
  unit?: String;
}
export interface RangeValue {
  key?: String;
  operator?: String;
  from?: Float | null;
  to?: Float | null;
  unit?: String;
  value?: String;
}
export interface MapDataInput {
  lat?: Float;
  long?: Float;
}
export interface CircleInput {
  center?: MapDataInput;
  radius?: Int;
}
export interface AreaType {
  area?: Float;
  unit?: string;
}
export interface MapCoordinates {
  bottomLeft?: MapDataInput;
  upperRight?: MapDataInput;
  circle?: CircleInput;
  polygonPoints: MapDataInput[];
}
export enum Direction {
  Bac = 'Bac',
  Nam = 'Nam',
  Dong = 'Dong',
  Tay = 'Tay',
  DongBac = 'DongBac',
  DongNam = 'DongNam',
  TayBac = 'TayBac',
  TayNam = 'TayNam',
  DongTuTrach = 'DongTuTrach',
  TayTuTrach = 'TayTuTrach',
  all = 'all',
}
export enum PostStatusType {
  pending = 'pending',
  active = 'active',
  expired = 'expired',
  all = 'all',
}
export enum TradingStatus {
  trading = 'trading',
  stop = 'stop',
  all = 'all',
}
export interface FileWithFullUrls {
  createdAt?: DateTime;
  creator?: String;
  fileId?: ID;
  originalUrl?: String;
  type?: String;
  updatedAt?: DateTime;
  urls?: Url[];
  size?: Int;
  name?: String;
}
export interface YoutubeMetadata {
  description?: String;
  thumbnailUrl?: String;
  title?: String;
  videoId?: String;
}
export interface YoutubeFile {
  fileId?: ID;
  type?: String;
  youtubeMetadata?: YoutubeMetadata;
}
export type MediaUnionWithFullUrls = FileWithFullUrls | YoutubeFile;
export interface Url {
  type?: String;
  url?: String;
}
export interface UserWithResource {
  userId?: String;
  resource?: String;
}
export interface Commission {
  id: ID;
  ownerId: String;
  owner?: UserWithResource;
  balance: Float;
  totalDeposit: Float;
}
export interface UserType {
  avatar?: MediaUnionWithFullUrls;
  email?: String;
  fullName?: String;
  gender?: String;
  id?: ObjectID;
  isPremiumAccount?: Boolean;
  memberId?: String;
  phone?: String;
  approvedAt?: Date;
  createdAt?: Date;
  userId?: ID;
  province?: CommonAddress;
  type?: String;
  commissionGenerated?: Float;
  children?: [String];
  totalChildren?: Float;
  developerId?: ID;
  developer?: UserType;
  birthDate?: Date;
  idNumner?: String;
  commission?: Commission;
  commissionChildrenCreatedForF0?: Float;
  levelMember?: String;
}
export interface CommonAddress {
  id?: ObjectID;
  name?: String;
  title?: String;
}
export interface Map {
  coordinates?: Float[];
  type?: String;
}
export interface RealEstateSchedule {
  actualPeriod?: Int;
  basicAmount?: Float;
  createdAt?: DateTime;
  endTime?: BigInt;
  id?: ID;
  period?: Int;
  postType?: Int;
  startTime?: BigInt;
  status?: String;
  updatedAt?: DateTime;
  vat?: Float;
}
export type ItemType =
  | RealEstate
  | PremiumRealEstate
  | RealEstateProjectRealEstate
  | NeededRealEstate
  | BaseProject
  | ProductInProjectRealEstate
  | ExternalCompany;
export interface SavedItem {
  belongsToCollection?: Collection;
  createdAt?: DateTime;
  createdItemDate?: DateTime;
  id?: ID;
  item?: ItemType;
  note?: String;
  owner?: UserType;
  type?: String;
  updatedAt?: DateTime;
}
export interface Collection {
  id?: ID;
  name?: String;
  owner?: ObjectID;
  totalItems?: Int;
  type?: String;
}
export enum ApprovalStatusEnumType {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
}
export interface Area {
  area?: Int;
  unit?: String;
}
export interface PriceByStage {
  price?: Float;
  stageName?: String;
  unitPrice?: String;
}
export interface AreaByStage {
  areaType?: Float;
  stages?: PriceByStage[];
  unit?: String;
}
export interface InternalPriceByStage {
  name?: String;
  priceByStage?: RangeValue;
  typeArea?: Area;
}
export interface AreaByWidthLength {
  length?: Float;
  unit?: String;
  width?: Float;
}
export interface Apartment {
  reaByStageList?: AreaByStage[];
  bedroomArea?: RangeValue;
  bedroomCount?: RangeValue;
  priceByStages?: InternalPriceByStage[];
  toiletArea?: RangeValue;
  toiletCount?: RangeValue;
  views?: String[];
}
export interface Townhouse {
  areaByStageList?: AreaByStage[];
  areaList?: AreaByWidthLength[];
  floorCount?: Int;
  priceByStages?: InternalPriceByStage[];
  views?: String[];
}
export interface Condotel {
  areaByStageList?: AreaByStage[];
  bedroomArea?: RangeValue;
  bedroomCount?: RangeValue;
  priceByStages?: InternalPriceByStage[];
  toiletArea?: RangeValue;
  toiletCount?: RangeValue;
  view?: String[];
  views?: String[];
}
export interface CommercialArea {
  areaByStageList?: AreaByStage[];
  kioskCount?: Int;
}
export interface IndependentVilla {
  areaByStageList?: AreaByStage[];
  areaList?: AreaByWidthLength[];
  bedroomArea?: RangeValue;
  bedroomCount?: RangeValue;
  floorCount?: RangeValue;
  priceByStages?: InternalPriceByStage[];
  views?: String[];
}
export interface Shophouse {
  areaByStageList?: AreaByStage[];
  areaList?: AreaByWidthLength[];
  priceByStages?: InternalPriceByStage[];
  toiletCount?: Int;
  views?: String[];
}
export interface ShoppingMallService {
  from?: Int;
  serviceName?: String;
  to?: Int;
}
export interface B2BCustomer {
  career?: String;
  companyID?: String;
  contactPhones?: String[];
  createdAt?: DateTime;
  creator?: String;
  deletedBy?: String;
  demand?: String;
  describeNeeded?: String;
  district?: String;
  districtName?: String;
  editor?: String;
  email?: String;
  finance?: RangeValue;
  fullAddress?: String;
  fullName?: String;
  gender?: String;
  houseNumber?: String;
  id?: ID;
  memberId?: String;
  previousOwner?: String;
  province?: String;
  provinceName?: String;
  street?: String;
  streetName?: String;
  system?: String;
  updatedAt?: DateTime;
  ward?: String;
  wardName?: String;
}
export interface RealEstate {
  realEstateId?: String;
  alleyTurnCount?: Int;
  alleyWidth?: Float;
  approvalReason?: String;
  approvalStatus?: String;
  approvedAt?: DateTime;
  approvedBy?: UserType;
  area?: Float;
  bathroomCount?: Int;
  bedRoomArea?: Float;
  bedroomCount?: Int;
  block?: String;
  buildingType?: String;
  career?: String;
  clearanceArea?: Float;
  commission?: String;
  company?: String;
  companyNote?: String;
  constructionArea?: Float;
  contactEmail?: String;
  contactName?: String;
  contactPhone1?: String;
  contactPhone2?: String;
  contactPhones?: String[];
  createdAt?: DateTime;
  creator?: UserType;
  demandEstate?: String;
  direction?: String;
  district?: CommonAddress;
  districtId?: ObjectID;
  editor?: UserType;
  endShowTime?: BigInt;
  equipmentsAndServices?: String[];
  fixedUtilities?: String[];
  floorArea?: Float;
  floorCount?: Int;
  floorId?: String;
  frontHomeAlleyWidth?: Float;
  shortAddress?: String;
  fullAddress?: String;
  fullSlug?: String;
  has360Image?: Boolean;
  hasAlley?: Boolean;
  hasImage?: Boolean;
  hasMezzanine?: Boolean;
  hasSeaView?: Int;
  hasVideo?: Boolean;
  hotelStar?: Int;
  houseNumber?: String;
  id?: ID;
  industryTrade?: String;
  investor?: String;
  isAuthorizedRe?: Boolean;
  isForeclosure?: Boolean;
  isLeadRE?: Boolean;
  isDeleted?: Boolean;
  isForSell?: Boolean;
  isIncognito?: Boolean;
  isNeedToRenew?: Boolean;
  isNewConstruction?: Boolean;
  isSaleOffRE?: Boolean;
  isSaved?: Boolean;
  latestRenewInfo?: RealEstateSchedule;
  legalMediaUrls?: MediaUnionWithFullUrls[];
  legals?: String[];
  length?: Float;
  localUtilities?: String[];
  location?: Map;
  map?: Map;
  mediaUrls?: MediaUnionWithFullUrls[];
  mediaUrlsIds?: String[];
  motelName?: String;
  numberOfPostingDay?: Int;
  onFloor?: Int;
  originalProductId?: String;
  ownerEmail?: String;
  ownerName?: String;
  ownerPhone?: String[];
  panoramicImages?: MediaUnionWithFullUrls[];
  postContent?: String;
  postStatus?: String;
  postTitle?: String;
  postType?: Int;
  price?: String;
  priceOfOwner?: String;
  pricePerSqMeter?: Float;
  priceUnit?: String;
  priceUnitOfOwner?: String;
  privateAttachments?: String[];
  privateNote?: String;
  project?: CommonAddress;
  projectId?: String;
  province?: CommonAddress;
  provinceId?: ObjectID;
  provinceName?: String;
  realEstateType?: String;
  rear?: Float;
  remainingActivePost?: String;
  roomArea?: Float;
  roomCount?: Int;
  roomNumber?: String;
  saveItem?: SavedItem;
  service?: String;
  shophouseField?: String;
  slug?: String;
  startShowTime?: BigInt;
  street?: CommonAddress;
  streetId?: ObjectID;
  streetName?: String;
  timeForRent?: Int;
  timeForRentUnit?: String;
  tnccPrice?: Float;
  toiletCount?: Int;
  tradingStatus?: String;
  type?: String;
  updatedAt?: DateTime;
  utilities?: String[];
  villaView?: String[];
  ward?: CommonAddress;
  wardId?: ObjectID;
  warehouseRoomCount?: Int;
  warehouseType?: String;
  width?: Float;
  breadScrumbs?: BreadScrumb[];
  numberOfRemaining?: Int;
  vr360Urls?: String[];
  farmLandUse?: FarmLandUseEnumType;
  pageNo?: String;
  parcel?: String;
  contactInfo?: UserType;
  leadREStatus?: String;
  contractCount?: Int;
  guestReportCount?: Int;
  enableAction?: Boolean;
}
export interface PremiumRealEstate {
  alleyTurnCount?: Int;
  alleyWidth?: Float;
  approvalStatus?: ApprovalStatusEnumType;
  approvedAt?: DateTime;
  approvedBy?: UserType;
  area?: Float;
  bedRoomArea?: Float;
  bedroomCount?: Int;
  block?: String;
  buildingType?: String;
  company?: String;
  constructionArea?: Float;
  contactEmail?: String;
  contactName?: String;
  contactPhone1?: String;
  contactPhone2?: String;
  createdAt?: DateTime;
  creator?: UserType;
  demandEstate?: String;
  direction?: String;
  district?: CommonAddress;
  districtName?: String;
  editor?: UserType;
  equipmentsAndServices?: String[];
  fixedUtilities?: String[];
  floorCount?: Int;
  fullAddress?: String;
  hasAlley?: Boolean;
  hasMezzanine?: Boolean;
  hasSeaView?: Int;
  hotelStar?: Int;
  houseNumber?: String;
  id?: ID;
  investor?: String;
  isAuthorizedRe?: Boolean;
  isForSell?: Boolean;
  isIncognito?: Boolean;
  isNewConstruction?: Boolean;
  isRead?: Boolean;
  isSaleOffRE?: Boolean;
  isSaved?: Boolean;
  legalMediaUrls?: MediaUnionWithFullUrls[];
  legals?: String[];
  length?: Float;
  localUtilities?: String[];
  location?: Map;
  map?: Map;
  mediaUrls?: MediaUnionWithFullUrls[];
  mediaUrlsIds?: String[];
  motelName?: String;
  onFloor?: Int;
  originalProductId?: String;
  ownerEmail?: String;
  ownerName?: String;
  ownerPhone?: String[];
  postContent?: String;
  postStatus?: String;
  postTitle?: String;
  price?: String;
  pricePerSqMeter?: Float;
  priceUnit?: String;
  privateNote?: String;
  project?: String;
  province?: CommonAddress;
  provinceName?: String;
  realEstateId?: String;
  rear?: Float;
  roomArea?: Float;
  roomCount?: Int;
  roomNumber?: String;
  saveItem?: SavedItem;
  service?: String;
  shophouseField?: String;
  street?: CommonAddress;
  streetName?: String;
  timeForRent?: Int;
  timeForRentUnit?: String;
  tnccPrice?: Float;
  toiletCount?: Int;
  tradingStatus?: String;
  type?: String;
  typeString?: String;
  updatedAt?: DateTime;
  utilities?: String[];
  villaView?: String[];
  ward?: CommonAddress;
  wardName?: String;
  warehouseRoomCount?: Int;
  warehouseType?: String;
  width?: Float;
}
export interface RealEstateProjectRealEstate {
  apartment?: Apartment;
  apartmentCount?: Int;
  area?: Float;
  areaRangeList?: RangeValue;
  areaUnit?: String;
  attachments?: MediaUnionWithFullUrls[];
  blockCount?: Int;
  blockName?: String;
  blockRange?: RangeValue;
  buildingCount?: Int;
  buildingType?: String;
  cinemaCountRange?: RangeValue;
  commercialArea?: CommercialArea;
  commercialAreaCount?: Int;
  commercialFloorCount?: Int;
  company?: String;
  companyNote?: String;
  condotel?: Condotel;
  condotelCount?: Int;
  constructionArea?: Float;
  constructionAreaRange?: RangeValue;
  constructionAreaUnit?: String;
  contactEmail?: String;
  contactName?: String;
  contactPhone1?: String;
  contactPhone2?: String;
  createdAt?: DateTime;
  creator?: UserType;
  demandEstate?: String;
  diningAreaCountRange?: RangeValue;
  directions?: String[];
  district?: CommonAddress;
  districtId?: String;
  districtName?: String;
  doubleVilla?: IndependentVilla;
  doubleVillaCount?: Int;
  editor?: UserType;
  endShowTime?: BigInt;
  equipmentsAndServices?: String[];
  exclusiveSupplier?: String;
  exclusiveSuppliers?: String[];
  exclusiveSupplierSub?: String[];
  factoryCount?: Int;
  factoryFunctions?: String;
  fixedUtilities?: String[];
  floorCountCanBuild?: Int;
  floorCountPerBockRange?: RangeValue;
  floorCountRange?: RangeValue;
  floorCountRangeList?: RangeValue;
  frontSideDescription?: String;
  frontSideImages?: MediaUnionWithFullUrls[];
  fullAddress?: String;
  has360Image?: Boolean;
  hasAlley?: Boolean;
  hasImage?: Boolean;
  hasVideo?: Boolean;
  houseNumber?: String;
  id?: ID;
  independentVilla?: IndependentVilla;
  independentVillaCount?: Int;
  industryTrade?: String;
  introduction?: String;
  investor?: String;
  investorEmail?: String;
  investorName?: String;
  investorPhones?: String[];
  isForSell?: Boolean;
  isIncognito?: Boolean;
  isNeedToRenew?: Boolean;
  isSaved?: Boolean;
  isSharing?: Boolean;
  landCount?: Int;
  landDescription?: String;
  landImages?: MediaUnionWithFullUrls[];
  latestRenewInfo?: RealEstateSchedule;
  legalMediaUrls?: MediaUnionWithFullUrls[];
  legals?: String[];
  length?: Float;
  localUtilities?: String[];
  location?: Map;
  map?: Map;
  mapPositionDescription?: String;
  mapPositionImages?: MediaUnionWithFullUrls[];
  mediaTypes?: String[];
  mediaUrls?: MediaUnionWithFullUrls[];
  mediaUrlsIds?: String[];
  offictel?: Apartment;
  offictelCount?: Int;
  originalProductId?: String;
  originalProjectId?: String;
  penthouse?: Apartment;
  penthouseCount?: Int;
  postContent?: String;
  postStatus?: String;
  postTitle?: String;
  postType?: Int;
  price?: String;
  priceByGap?: PriceByStage[];
  priceRange?: RangeValue;
  priceUnit?: String;
  privateNote?: String;
  progress?: String;
  projectName?: String;
  projectScale?: String;
  projectSubType?: String[];
  projectType?: String;
  projectView?: String[];
  province?: CommonAddress;
  provinceId?: String;
  provinceName?: String;
  realEstateId?: String;
  realEstateType?: String;
  rear?: Float;
  roomCount?: Float;
  saveItem?: SavedItem;
  service?: String;
  services?: ShoppingMallService;
  shophouse?: Shophouse;
  shopHouseCount?: Int;
  standardStar?: Int;
  startShowTime?: BigInt;
  street?: CommonAddress;
  streetId?: String;
  streetName?: String;
  tags?: String[];
  taxId?: String;
  timeForRent?: Int;
  timeForRentUnit?: String;
  totalLandArea?: Area;
  townhouse?: Townhouse;
  townhouseCount?: Int;
  tradingStatus?: String;
  tunnelCount?: Int;
  type?: String;
  typeAreas?: Area[];
  updatedAt?: DateTime;
  utilities?: String[];
  ward?: CommonAddress;
  wardId?: String;
  wardName?: String;
  warehouseCount?: Int;
  width?: Float;
  zoneCount?: Int;
}
export interface NeededRealEstate {
  approvalReason?: String;
  approvalStatus?: String;
  approvedAt?: DateTime;
  approvedBy?: UserType;
  area?: RangeValue;
  bedroomCount?: RangeValue;
  blockName?: String;
  buildingType?: String;
  clearanceArea?: RangeValue;
  company?: String;
  constructionArea?: RangeValue;
  contactEmail?: String;
  contactName?: String;
  contactPhones?: String[];
  country?: String;
  createdAt?: DateTime;
  creator?: UserType;
  customer?: B2BCustomer;
  demandEstate?: String;
  direction?: String[];
  district?: CommonAddress;
  editor?: UserType;
  endShowTime?: BigInt;
  equipmentsAndServices?: String[];
  expectPrice?: RangeValue;
  factoryFunctions?: String;
  fixedUtilities?: String[];
  floorCount?: RangeValue;
  floorPosition?: RangeValue;
  fullAddress?: String;
  hasAlley?: Boolean;
  hasMezzanine?: Boolean;
  hotelStar?: Float;
  id?: ID;
  industryTrade?: String;
  investor?: String;
  isDeleted?: Boolean;
  isIncognito?: Boolean;
  isNeedToBuy?: Boolean;
  isNeedToRenew?: Boolean;
  isNewly?: Boolean;
  isSaved?: Boolean;
  latestRenewInfo?: RealEstateSchedule;
  legals?: String[];
  length?: RangeValue;
  localUtilities?: String[];
  location?: Map;
  mediaTypes?: String[];
  mediaUrls?: MediaUnionWithFullUrls[];
  mediaUrlsIds?: String[];
  neededRealEstateId?: String;
  numberOfPostingDay?: Int;
  postContent?: String;
  postStatus?: String;
  postTitle?: String;
  postType?: Int;
  prevSlug?: String;
  price?: RangeValue;
  privateNote?: String;
  projectName?: String;
  province?: CommonAddress;
  realEstateId?: String;
  rear?: RangeValue;
  remainingActivePost?: String;
  saveItem?: SavedItem;
  service?: String;
  slug?: String;
  startShowTime?: BigInt;
  street?: CommonAddress;
  tags?: String[];
  timeForRent?: RangeValue;
  toiletCount?: RangeValue;
  tradingStatus?: String;
  type?: String;
  updatedAt?: DateTime;
  utilities?: String[];
  villaView?: String[];
  ward?: CommonAddress;
  warehouseRoomCount?: RangeValue;
  warehouseType?: String;
  width?: RangeValue;
}
export interface ExternalCompany {
  address?: String;
  companyFields?: String[];
  createdAt?: DateTime;
  description?: String;
  district?: String;
  email?: String;
  id?: ID;
  logo?: String;
  name?: String;
  phone?: String;
  province?: String;
  provinceName?: String;
  taxId?: String;
  type?: String;
  updatedAt?: DateTime;
}

export interface BaseProject {
  id?: ID;
  projectType?: String;
  projectStatus?: String;
  projectName?: String;
  houseNumber?: String;
  street?: CommonAddress;
  ward?: CommonAddress;
  district?: CommonAddress;
  province?: CommonAddress;
  fullAddress?: String;
  projectArea?: AreaType;
  totalBlock?: Int;
  totalFloor?: Int;
  totalProduct?: Int;
  projectOverview?: String;
  expectedPrice?: RangeValue;
  mediaUrls?: MediaUnionWithFullUrls[];
  vr360Urls?: String[];
  commission?: String;
  creator?: UserType;
  apartmentCount?: Int;
  blocks?: CommonAddress[];
  project?: BaseProject;
  savedItem?: SavedItem;
  createdAt?: DateTime;
  updatedAt?: DateTime;
}
export interface ProductInProjectRealEstate {
  id?: ID;
  projectId?: ID;
  projectName?: String;
  fullAddress?: String;
  isForSell?: Boolean;
  blockName?: String;
  floorName?: String;
  roomNumber?: String;
  mediaUrls?: MediaUnionWithFullUrls[];
  vr360Urls?: String[];
  savedItem?: SavedItem;
  createdAt?: DateTime;
  updatedAt?: DateTime;
}

export interface ListRealEstate extends PaginationOf {
  items?: RealEstate[];
}

export interface GetRealEstatesData {
  getRealEstates?: ListRealEstate;
}
export interface GetRealEstatesVariables {
  ids?: ObjectID[];
  filterOnMap?: MapCoordinates;
  searchString?: String;
  typeOfDemand?: TypeOfDemandEnumType;
  kindOfRealEstate?: String[];
  realEstateType?: RealEstateTypeEnumType[];
  province?: String;
  district?: String;
  ward?: String;
  street?: String;
  commission?: String;
  price?: FromTo;
  priceUnit?: String;
  area?: FromTo;
  length?: FromTo;
  width?: FromTo;
  rear?: FromTo;
  floorArea?: FromTo;
  frontHomeAlleyWidth?: FromTo;
  alleyWidth?: FromTo;
  bedRoomArea?: FromTo;
  floorCount?: FromTo;
  floorPosition?: FromTo;
  roomCount?: FromTo;
  bedroomCount?: FromTo;
  toiletCount?: FromTo;
  blockCount?: FromTo;
  projectName?: String;
  hasSeaView?: Boolean;
  villaView?: String[];
  hotelStar?: Float;
  investor?: String;
  buildingType?: String;
  direction?: Direction[];
  note?: String;
  fixedUtilities?: String[];
  utilities?: String[];
  localUtilities?: String[];
  equipmentsAndServices?: String[];
  postStatus?: PostStatusType[];
  postType?: Int[];
  tradingStatus?: TradingStatus[];
  sort?: JSONObject;
  isAuthorizedRe?: Boolean;
  isForeclosure?: Boolean;
  isSaleOffRE?: Boolean;
  isPaidRE?: Boolean;
  isLeadRE?: Boolean;
  phone?: String;
  ownerPhone?: String;
  mediaTypes?: String[];
  company?: String;
  projectIds?: String[];
  houseNumber?: String;
  realEstateId?: String;
  contactName?: String;
  ownerName?: String;
  creator?: String;
  creatorName?: String;
  page?: Int;
  limit?: Int;
  isProduct?: Boolean;
  hasAlley?: Boolean;
  createdAt?: FromToDateInput;
  updatedAt?: FromToDateInput;
  startShowTime?: FromTo;
  warehouseRoomCount?: FromTo;
  constructionArea?: FromTo;
  except?: ObjectID;
  approvedAt?: FromToDateInput;
  approvedBy?: String;
  hasImage?: Boolean;
  hasVideo?: Boolean;
  has360Image?: Boolean;
  approvalStatus?: ApprovalStatusEnumType;
  parcel?: String;
  pageNo?: String;
}

export const GET_REAL_ESTATES = gql`
  query getRealEstates(
    $ids: [ObjectID]
    $filterOnMap: MapCoordinates
    $searchString: String
    $typeOfDemand: TypeOfDemandEnumType
    $kindOfRealEstate: [String]
    $realEstateType: [RealEstateTypeEnumType]
    $province: String
    $district: String
    $ward: String
    $street: String
    $commission: String
    $price: FromTo
    $priceUnit: String
    $area: FromTo
    $length: FromTo
    $width: FromTo
    $rear: FromTo
    $floorArea: FromTo
    $frontHomeAlleyWidth: FromTo
    $alleyWidth: FromTo
    $bedRoomArea: FromTo
    $floorCount: FromTo
    $floorPosition: FromTo
    $roomCount: FromTo
    $bedroomCount: FromTo
    $toiletCount: FromTo
    $blockCount: FromTo
    $projectName: String
    $hasSeaView: Boolean
    $villaView: [String]
    $hotelStar: Float
    $investor: String
    $buildingType: String
    $direction: [Direction]
    $note: String
    $fixedUtilities: [String]
    $utilities: [String]
    $localUtilities: [String]
    $equipmentsAndServices: [String]
    $postStatus: [PostStatusType]
    $postType: [Int]
    $tradingStatus: [TradingStatus]
    $sort: JSONObject
    $isAuthorizedRe: Boolean
    $isForeclosure: Boolean
    $isSaleOffRE: Boolean
    $isPaidRE: Boolean
    $isLeadRE: Boolean
    $phone: String
    $ownerPhone: String
    $mediaTypes: [String]
    $company: String
    $projectIds: [String]
    $houseNumber: String
    $realEstateId: String
    $contactName: String
    $ownerName: String
    $creator: String
    $creatorName: String
    $page: Int
    $limit: Int
    $isProduct: Boolean
    $hasAlley: Boolean
    $createdAt: FromToDateInput
    $updatedAt: FromToDateInput
    $startShowTime: FromTo
    $warehouseRoomCount: FromTo
    $constructionArea: FromTo
    $except: ObjectID
    $approvedAt: FromToDateInput
    $approvedBy: String
    $hasImage: Boolean
    $hasVideo: Boolean
    $has360Image: Boolean
    $approvalStatus: ApprovalStatusEnumType
    $parcel: String
    $pageNo: String
  ) @api(name: realEstate) {
    getRealEstates(
      ids: $ids
      filterOnMap: $filterOnMap
      searchString: $searchString
      typeOfDemand: $typeOfDemand
      kindOfRealEstate: $kindOfRealEstate
      realEstateType: $realEstateType
      province: $province
      district: $district
      ward: $ward
      street: $street
      commission: $commission
      price: $price
      priceUnit: $priceUnit
      area: $area
      length: $length
      width: $width
      rear: $rear
      floorArea: $floorArea
      frontHomeAlleyWidth: $frontHomeAlleyWidth
      alleyWidth: $alleyWidth
      bedRoomArea: $bedRoomArea
      floorCount: $floorCount
      floorPosition: $floorPosition
      roomCount: $roomCount
      bedroomCount: $bedroomCount
      toiletCount: $toiletCount
      blockCount: $blockCount
      projectName: $projectName
      hasSeaView: $hasSeaView
      villaView: $villaView
      hotelStar: $hotelStar
      investor: $investor
      buildingType: $buildingType
      direction: $direction
      note: $note
      fixedUtilities: $fixedUtilities
      utilities: $utilities
      localUtilities: $localUtilities
      equipmentsAndServices: $equipmentsAndServices
      postStatus: $postStatus
      postType: $postType
      tradingStatus: $tradingStatus
      sort: $sort
      isAuthorizedRe: $isAuthorizedRe
      isForeclosure: $isForeclosure
      isSaleOffRE: $isSaleOffRE
      isPaidRE: $isPaidRE
      isLeadRE: $isLeadRE
      phone: $phone
      ownerPhone: $ownerPhone
      mediaTypes: $mediaTypes
      company: $company
      projectIds: $projectIds
      houseNumber: $houseNumber
      realEstateId: $realEstateId
      contactName: $contactName
      ownerName: $ownerName
      creator: $creator
      creatorName: $creatorName
      page: $page
      limit: $limit
      isProduct: $isProduct
      hasAlley: $hasAlley
      createdAt: $createdAt
      updatedAt: $updatedAt
      startShowTime: $startShowTime
      warehouseRoomCount: $warehouseRoomCount
      constructionArea: $constructionArea
      except: $except
      approvedAt: $approvedAt
      approvedBy: $approvedBy
      hasImage: $hasImage
      hasVideo: $hasVideo
      has360Image: $has360Image
      approvalStatus: $approvalStatus
      parcel: $parcel
      pageNo: $pageNo
    ) {
      hasNextPage
      hasPrevPage
      items {
        id
        postTitle
        postContent
        isForSell
        realEstateType
        demandEstate
        isSaved
        saveItem {
          id
          belongsToCollection {
            id
            type
            name
            owner
            totalItems
          }
        }
        area
        floorCount
        toiletCount
        bedroomCount
        isAuthorizedRe
        isForeclosure
        isLeadRE
        leadREStatus
        contractCount
        guestReportCount
        price
        createdAt
        slug
        fullSlug
        postType
        fullAddress
        shortAddress
        privateNote
        province {
          id
          name
        }
        mediaUrls {
          ... on FileWithFullUrls {
            fileId
            type
            originalUrl
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              videoId
            }
          }
        }
        creator {
          id
        }
        approvalStatus
        postStatus
        tradingStatus
      }
      limit
      nextPage
      page
      pagingCounter
      prevPage
      total
      totalPages
    }
  }
`;
