import { gql } from '@apollo/client';
import { String } from '../queries';
import { MutationOfString } from './verify-otp';

export interface DeleteNameCardData {
  deleteNameCard?: MutationOfString;
}
export interface DeleteNameCardVariables {
  nameCardId: String;
  userId: String;
}

export const DELETE_NAME_CARD = gql`
  mutation deleteNameCard($nameCardId: String!, $userId: String!) @api(name: users) {
    deleteNameCard(nameCardId: $nameCardId, userId: $userId) {
      payload
      success
      msg
    }
  }
`;
