import { gql } from '@apollo/client';

export interface GetNameCardConfigData {
  getNameCardConfig?: JSON[];
}
export interface GetNameCardConfigVariables {}

export const GET_NAME_CARD_CONFIG = gql`
  query getNameCardConfig @api(name: users) {
    getNameCardConfig
  }
`;
