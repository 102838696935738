import * as yup from 'yup';

export interface AddNameCardForm {
  value?: string;
}

export const addNameCardFormDefaultValues: AddNameCardForm = {
  value: undefined,
};

export const addNameCardFormSchema = yup.object().shape({
  value: yup.string().required('Vui lòng nhập liên kết & thông tin khác'),
});
