import { gql } from '@apollo/client';
import { CommentAbleType, CommentListOutputType } from '../mutations/create-comment';
import { DateTime, Int, ObjectID } from './get-real-estates';
import { PaginationOf } from './get-user-saved-real-estate';

export interface ListCommentParents extends PaginationOf {
  items?: CommentListOutputType[];
}

export interface GetListCommentParentsData {
  getListCommentParents?: ListCommentParents;
}
export interface GetListCommentParentsVariables {
  limit?: Int;
  page?: Int;
  lastTime?: DateTime;
  commentAbleId: ObjectID;
  commentAbleType: CommentAbleType;
}

export const GET_LIST_COMMENT_PARENTS = gql`
  query getListCommentParents(
    $limit: Int
    $page: Int
    $lastTime: DateTime
    $commentAbleId: ObjectID!
    $commentAbleType: CommentAbleType!
  ) @api(name: realEstate) {
    getListCommentParents(
      limit: $limit
      page: $page
      lastTime: $lastTime
      commentAbleId: $commentAbleId
      commentAbleType: $commentAbleType
    ) {
      hasNextPage
      limit
      page
      prevPage
      total
      hasPrevPage
      nextPage
      pagingCounter
      totalPages
      items {
        user {
          avatar {
            ... on FileWithFullUrls {
              fileId
              originalUrl
            }
          }
          email
          fullName
          id
          phones
        }
        parentComment
        totalChildren
        updatedAt
        id
        createdAt
        content
        commentAbleId
        commentChildren {
          id
          parentComment
          updatedAt
          user {
            avatar {
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
            email
            fullName
            id
            phones
          }
          createdAt
          content
          companyId
          commentAbleType
          commentAbleId
        }
      }
    }
  }
`;
