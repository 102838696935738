import { PHONE_NUMBER_REGEX } from 'globalConstants';
import {} from 'graphql/main/mutations';
import { TypeCustomerType } from 'graphql/main/queries';
import * as yup from 'yup';

export interface Option {
  value?: string;
  label?: string;
}

export interface CreateAndUpdateContractLeadForm {
  fullName?: string;
  typeCustomer?: TypeCustomerType;
  phone?: string;
  contractId?: Option;
  nameContract?: string;
  content?: string;
  contractDate?: string;
  contractValue?: string;
  startDate?: string;
  endDate?: string;
  type?: string;
  country?: string;
  province?: string;
  district?: string;
  ward?: string;
  street?: string;
  houseNumber?: string;
  area?: string;
  price?: string;
  hasAlley?: string;
  width?: string;
  length?: string;
  direction?: string;
  bedroomCount?: string;
  toiletCount?: string;
  floorCount?: string;
  attachments?: string[];
}

export const createAndUpdateContractLeadFormDefaultValues = {
  fullName: '',
  typeCustomer: TypeCustomerType.individual,
  phone: '',
  contractId: undefined,
  nameContract: '',
  content: undefined,
  contractDate: undefined,
  contractValue: undefined,
  startDate: undefined,
  endDate: undefined,
  type: undefined,
  country: undefined,
  province: undefined,
  district: undefined,
  ward: undefined,
  street: undefined,
  houseNumber: undefined,
  area: undefined,
  price: undefined,
  hasAlley: undefined,
  width: undefined,
  length: undefined,
  direction: undefined,
  bedroomCount: undefined,
  toiletCount: undefined,
  floorCount: undefined,
  attachments: undefined,
};

export const createAndUpdateContractLeadFormSchema = yup.object().shape({
  fullName: yup.string().required('Vui lòng nhập họ và tên khách hàng'),
  typeCustomer: yup.string().required('Vui lòng chọn loại khách hàng'),
  phone: yup
    .string()
    .required('Vui lòng nhập số điện thoại khách hàng')
    .matches(PHONE_NUMBER_REGEX, 'Vui lòng nhập số điện thoại đúng định dạng'),
  contractId: yup.object().required('Vui lòng chọn loại hợp đồng'),
  nameContract: yup.string().required('Vui lòng nhập số tên hợp đồng'),
});
