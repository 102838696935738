import { IconClose } from 'components/icons';
import { size as _size, isEmpty, isEqual, isUndefined } from 'lodash';
import { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
  name: string;
  size?: 'medium' | 'big';
  disabled?: boolean;
  required?: boolean;
  placeHolder?: boolean;
  type?: 'text' | 'password';
  autoComplete?: string;
  maxLength?: number;
  label?: string;
  error?: { message?: string };
  endIcon?: any;
  onChange?: (value?: string) => void;
  onBlur?: () => void;
}

const FormTextInput = ({
  name,
  size,
  disabled,
  required,
  placeHolder,
  type,
  autoComplete,
  maxLength,
  label,
  error,
  endIcon,
  onChange,
  onBlur,
}: Props) => {
  const isSizeBig = isEqual(size, 'big');
  const form = useFormContext<any>();
  const valueWatch = useWatch({ control: form.control, name });
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const clickWrapper = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const blur = () => {
    setIsFocused(false);
    onBlur?.();
  };
  const change = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    const newValue = event.target.value;
    form.setValue(name, newValue, { shouldValidate: true });
    onChange?.(newValue);
  };
  const clear = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const newValue = '';
    form.setValue(name, newValue, { shouldValidate: true });
    onChange?.(newValue);
  };

  return (
    <div className={`flex select-none flex-col space-y-[3px] ${disabled ? '' : 'cursor-text'}`}>
      <div className={disabled ? 'pointer-events-none' : ''} onClick={clickWrapper}>
        <fieldset
          className={`group relative flex items-center rounded-[8px] border px-[12px] ${
            error ? 'border-alert' : isFocused ? 'border-primary' : 'border-stroke'
          } ${isSizeBig ? 'h-[48px]' : 'h-[40px]'} ${disabled ? 'bg-disabled' : 'bg-paper'}`}
        >
          {isFocused || (!isFocused && !isEmpty(valueWatch)) ? (
            !placeHolder && (
              <legend className='pointer-events-none z-[1] mx-[2px] px-[4px] leading-[2px]'>
                <label className='text-[12px]'>
                  <span className='text-text2'>{label}</span>{' '}
                  {required && <span className='text-alert'>*</span>}
                </label>
              </legend>
            )
          ) : (
            <label className='pointer-events-none absolute top-1/2 -translate-y-1/2'>
              <span className='text-text2'>{label}</span>{' '}
              {required && <span className='text-alert'>*</span>}
            </label>
          )}
          <input
            ref={inputRef}
            value={valueWatch}
            type={type}
            autoComplete={autoComplete}
            maxLength={maxLength}
            tabIndex={disabled ? -1 : undefined}
            className='w-full bg-transparent'
            onFocus={() => setIsFocused(true)}
            onBlur={blur}
            onChange={change}
          />
          {!isEmpty(valueWatch) && (
            <button type='button' className='ml-[8px] hidden group-hover:flex' onClick={clear}>
              <IconClose className='h-[20px] w-[20px] text-text2' />
            </button>
          )}
          {endIcon && <div className='ml-[8px] flex items-center border-l pl-[8px]'>{endIcon}</div>}
        </fieldset>
      </div>
      {(!isUndefined(maxLength) || error) && (
        <div className='flex w-full justify-between py-[3px] px-[12px] text-[12px] leading-[15px]'>
          <span className='error-message text-alert'>{error?.message}</span>
          {maxLength && (
            <span className='text-right ml-auto text-text2'>{`${_size(
              valueWatch,
            )}/${maxLength}`}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormTextInput;
